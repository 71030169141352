@import url("https://cdn.vash.network/fontawesome/6.5.1/css/all.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Thin.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-ExtraLight.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Light.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Regular.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Medium.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-SemiBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Bold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 800;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-ExtraBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Black.woff2")
    format("woff2");
  font-display: swap;
}

/* Font Definition*/
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Thin.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-ExtraLight.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Light.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Regular.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Medium.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-SemiBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Bold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 800;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-ExtraBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Black.woff2")
    format("woff2");
  font-display: swap;
}

body {
  font-family: "Noto Sans TC", "Noto Sans JP", sans-serif !important;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
